<template>
  <div class="container">
    <div class="pa-20">
      <img class="logo" src="~@/assets/img/logo.png" />
      <div class="flex f-center back-box ma-t-10" @click="$router.go(-1)">
        <img class="back" src="~@/assets/img/back.png" />
        <span>返回</span>
      </div>

      <div
        v-for="(item, index) in list"
        :key="index"
        class="flex f-between ma-t-20 fon-20 item-box pa-30"
      >
        <div>
          <div>预约类型： {{ $util.getAppointmentType(item.type) }}</div>
          <div class="ma-t-10">来访人公司： {{ item.company }}</div>
          <div class="ma-t-10">来访人姓名：{{ item.name }}</div>
          <div class="ma-t-10">来访车牌号：{{ item.plateNo }}</div>
          <div class="ma-t-10">
            来访类型：{{ $util.getVisitType(item.visitType) }}
          </div>
          <div class="ma-t-10">
            状态：{{ $util.getAppointmentStatus(item.status) }}
          </div>
        </div>
        <div class="flex flex-direction f-around">
          <div>
            <van-button
              type="primary"
              size="normal"
              class="width100"
              color="#003AA9"
              @click="showDetail(item)"
              >查看详情</van-button
            >
          </div>
          <div v-if="item.visitType=='41' && item.status==2">
            <van-button
              type="primary"
              size="normal"
              class="width100"
              color="#f59a23"
              @click="showForm(item)"
              >填写过磅重量</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible">
      <div class="fon-18">
        <div class="ma-tb-10">
          预约类型：{{ $util.getAppointmentType(info.type) }}
        </div>
        <div class="ma-tb-10">来访公司名称：{{ info.company }}</div>
        <div class="ma-tb-10">来访人姓名：{{ info.name }}</div>
        <div class="ma-tb-10">来访人手机号：{{ info.phone }}</div>
        <div class="ma-tb-10">受访人姓名：{{ info.intvName }}</div>
        <div class="ma-tb-10">受访人座机/手机：{{ info.intvPhone }}</div>
        <div class="ma-tb-10">受访人部门：{{ info.intvDepartment }}</div>
        <div class="ma-tb-10">来访车牌号：{{ info.plateNo }}</div>
        <div class="ma-tb-10">来访时间：{{ info.visitDate }} {{info.visitEndDate? '~ '+info.visitEndDate : '' }}</div>
        <div class="ma-tb-10">
          来访类型：{{ $util.getVisitType(info.visitType) }}
        </div>
        <div>来访理由：{{ info.reason }}</div>
      </div>
    </el-dialog>

    <!-- 填写过磅重量 -->
    <el-dialog v-model="dialogFormVisible">
      <div>
        <div class="flex fon-18 f-center">
          <div class="width50 t-right"><span class="c-red">*</span> 过磅重量（进）</div>
          <el-input
            size="large"
            v-model="weightIn"
            label=""
            placeholder="请输入"
          />
        </div>
        <div class="ma-t-20">
          <van-button
            type="primary"
            size="normal"
            class="width100"
            color="#003AA9"
            @click="submitWeightIn()"
            >确认</van-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var _this;
export default {
  data() {
    return {
      dialogFormVisible: false,
      dialogTableVisible: false,
      list: [],
      info: {},
      weightIn: "", //过磅重量
    };
  },
  created() {
    _this = this;
    _this.loadData();
  },
  methods: {
    //获取列表
    loadData() {
      _this
        .$request({
          url: "/security/list",
          method: "GET",
        })
        .then((result) => {
          _this.list = result;
        });
    },
    //查看详情
    showDetail(item) {
      _this.info = item;
      _this.dialogTableVisible = true;
    },
    //显示表单
    showForm(item) {
      _this.info = item;
      _this.dialogFormVisible = true;
    },
    //填写过磅重量
    submitWeightIn() {
      _this
        .$request({
          url: "/security/weightIn",
          method: "POST",
          data: {
            visitId: _this.info.id,
            weightIn: _this.weightIn,
          },
        })
        .then((result) => {
          _this.$toast("操作成功");
          _this.dialogFormVisible=false
          _this.loadData();

        });
    },
  },
};
</script>

<style lang="less" scoped>
.item-box {
  background: #eaeef7;
}

.c-red {
  color: red;
}
.container{
    overflow-y: auto;
}
</style>